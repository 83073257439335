export function ContactIcon({icon, text, url}) {
  return (
    <div className="contact-icon">
      <a href={url} target="_blank">
        <div className="contact-icon-box">
          <i className={icon}></i>
        </div>
      </a>
      <p>{text}</p>
    </div>
  )
}