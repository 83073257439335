// import guyPicture from "../../resources/Guy Passport Black and White.jpeg";
// import guyColor from "../../resources/guy-color.jpg";
// import guyLaptop from "../../resources/guy-laptop.jpeg";
import { useEffect } from "react";
import guyLaptop from "../../resources/guy-laptop.webp";

// function preloadImage (src) {
//   return new Promise((resolve, reject) => {
//     const img = new Image()
//     img.onload = function() {
//       resolve(img);
//     }
//     img.onerror = img.onabort = function() {
//       reject(src);
//     }
//     img.src = src;
//   })
// }

export function LandingPicture({setIsLandingPictureLoaded}) { 

  // useEffect(() => { 
  //   async function runPreloadImage() {
  //     const image = await preloadImage(guyLaptop);
  //   }
  //   runPreloadImage();
  // })

  return (
    <div className="main-picture-container">
      <img src={guyLaptop} id="main-picture" onLoad={() => setIsLandingPictureLoaded(true)} />
      <div className="main-picture-border" id="main-picture-top-border"></div>
      <div className="main-picture-border" id="main-picture-right-border"></div>
      <div className="main-picture-border" id="main-picture-bottom-border"></div>
      <div className="main-picture-border" id="main-picture-left-border"></div>
    </div>
  )
}