function handleClick(e) {
  let element = e.target;
  if (e.target.tagName === "VIDEO" || e.target.tagName === "IMG") {
    element = e.target.parentNode.parentNode;
  }
  if (e.target.className === "thumbnail-container") {
    element = e.target.parentNode;
  }
  const number = +element.classList[1].replace("project-", "");
  document
    .querySelectorAll(".project-modal")
    [number - 1].classList.add("visible");
  if (
    document.querySelectorAll(".project-modal-video-container")[number - 1]
      .children[0].tagName === "VIDEO"
  ) {
    document
      .querySelectorAll(".project-modal-video-container")
      [number - 1].children[0].play();
  }

  document.querySelector("body").classList.add("modal-open");
}

export function Project({ number, thumbnail, gif, mode, title }) {
  return (
    <div className={`project project-${number}`} onClick={handleClick}>
      <div className="thumbnail-container">
        {mode === "video" && (
          <video autoPlay muted loop webkit-playsinline="true" playsInline>
            <source src={thumbnail} type="video/mp4" />
          </video>
        )}
        {(mode === "gif" || !thumbnail) && <img src={gif} />}
      </div>
      <p className={`title project-${number}`}>{title}</p>
    </div>
  );
}
