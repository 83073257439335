import javascriptLogo from "../../resources/logos/javascript_logo.webp";
import typescriptLogo from "../../resources/logos/typescript_logo.webp";
import reactLogo from "../../resources/logos/react_logo.webp";
import reduxLogo from "../../resources/logos/redux_logo.webp";
import htmlLogo from "../../resources/logos/html_logo.webp";
import cssLogo from "../../resources/logos/css_logo.webp";
import nodeJsLogo from "../../resources/logos/nodejs_logo.webp";
import cSharpLogo from "../../resources/logos/csharp_logo.webp";
import gitLogo from "../../resources/logos/git_logo.webp";
import githubLogo from "../../resources/logos/github_logo.webp";
import pythonLogo from "../../resources/logos/python_logo.webp";
import mongoDbLogo from "../../resources/logos/mongo_db_logo.webp";
import sqlLogo from "../../resources/logos/sql_logo.webp";
import regexLogo from "../../resources/logos/regex_logo.webp";
import jestLogo from "../../resources/logos/jest_logo.webp";
import expressJsLogo from "../../resources/logos/express_js.webp";
import { SkillContainer } from "../SkillContainer/SkillContainer";

const skills = {
  Javascript: javascriptLogo,
  Typescript: typescriptLogo,
  React: reactLogo,
  Redux: reduxLogo,
  HTML: htmlLogo,
  CSS: cssLogo,
  "Node.js": nodeJsLogo,
  "Express.js": expressJsLogo,
  MongoDB: mongoDbLogo,
  Git: gitLogo,
  GitHub: githubLogo,
  Python: pythonLogo,
  SQL: sqlLogo,
  Regex: regexLogo,
  Jest: jestLogo,
  "C#": cSharpLogo,
};

export function SkillsContainer() {
  return (
    <div className="skills-container">
      {Object.entries(skills).map(([skill, url]) => (
        <SkillContainer skill={skill} url={url} key={skill + "-container"} />
      ))}
    </div>
  );
}
