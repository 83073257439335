import { ProjectModal } from "../ProjectModal/ProjectModal";
import { projects } from "../../data/ProjectsData";

// const projects = {
//   1: {
//     title: "Project One"
//   },
//   2: {
//     title: "Project Two"
//   },
//   3: {
//     title: "Project Three"
//   },
//   4: {
//     title: "Project Four"
//   },
//   5: {
//     title: "Project Five"
//   }
// }

export function ProjectModalsContainer() {
  return (
    <div className="project-modals-container">
      {Object.keys(projects).map((key, index) => (
        <ProjectModal
          title={key}
          element={projects[key].element}
          thumbnail={projects[key].thumbnail}
          gif={projects[key].gif}
          url={projects[key].url}
          gitHubUrl={projects[key].gitHubUrl}
          key={`project-modal-${index + 1}`}
        />
      ))}
    </div>
  );
}
