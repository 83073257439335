import { HomePage } from './pages/HomePage/HomePage';
import './App.css';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  )
}

export default App;
