import stargazerVid from "../resources/videos/stargazer.mp4";
import stargazerGif from "../resources/videos/stargazer.gif";
import pokedexVid from "../resources/videos/pokedex-short.mp4";
import pokedexGif from "../resources/videos/pokedex-short.gif";
import quizifyVid from "../resources/videos/quizify.mp4";
import quizifyGif from "../resources/videos/quizify.gif";
import hebrewVid from "../resources/videos/hebrew-typer.mp4";
import hebrewGif from "../resources/videos/hebrew-typer.gif";
import ninaVid from "../resources/videos/nina2.mp4";
import ninaGif from "../resources/videos/nina2.gif";

export const projects = {
  Stargazer: {
    thumbnail: stargazerVid,
    gif: stargazerGif,
    url: "https://guypollack.github.io/stargazer/",
    gitHubUrl: "https://github.com/guypollack/stargazer",
    element: (
      <p>
        A game I created with the Phaser JavaScript framework to share the
        beauty and excitement of exploring the stars! I used multiple scenes,
        game physics, sound effects and animations to capture the thrill of
        making new discoveries in space, which I was lucky enough to do at{" "}
        <a href="https://en.wikipedia.org/wiki/SN_2014J" target="_blank">
          university
        </a>
      </p>
    ),
  },
  Pokedex: {
    thumbnail: pokedexVid,
    gif: pokedexGif,
    url: "https://pokedex-gp.netlify.app",
    gitHubUrl: "https://github.com/guypollack/pokedex",
    element: (
      <div>
        <p>
          A searchable Pokedex (Pokemon encyclopedia) created using React Redux
          and React Router with data from PokeAPI. Make sure to log in to access
          all the features, including a game! Enable Gameboy mode for a true
          retro experience
        </p>
        <p>
          This project won 1st place at{" "}
          <a
            href="https://www.codecademy.com/pages/project-showcase"
            target="_blank"
          >
            Codecademy's Learner Project Showcase competition
          </a>
        </p>
      </div>
    ),
  },
  Quizify: {
    thumbnail: quizifyVid,
    gif: quizifyGif,
    url: "http://quizify-music.surge.sh",
    gitHubUrl: "https://github.com/guypollack/quizify",
    element: (
      <div>
        <p>
          A ReactJS quiz game using Spotify API. Test your knowledge of your
          favourite artists' top tracks!
        </p>
        <p>
          To use this website, sign in to Spotify when prompted using these
          details:
        </p>
        <p>Username: quizify_user@guypollack.com | Password: LetMePlay</p>
      </div>
    ),
  },
  "Hebrew Typer": {
    thumbnail: hebrewVid,
    gif: hebrewGif,
    url: "https://guypollack.github.io/hebrewtyping/",
    gitHubUrl: "https://github.com/guypollack/hebrewtyping",
    element: (
      <p>
        A vanilla JS project that I created after moving to Tel Aviv, to teach
        myself how to type in Hebrew! Allows users to customize what they want
        to learn and provides stats to track their progress
      </p>
    ),
  },
  "Nina Rauch": {
    thumbnail: ninaVid,
    gif: ninaGif,
    url: "https://www.ninazrauch.com",
    gitHubUrl: "",
    element: (
      <p>
        Personal website for social impact leader, activist and founder Nina
        Rauch
      </p>
    ),
  },
};
