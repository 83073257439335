import { useEffect } from "react"
import guyCv from "../../resources/pdfs/Guy Pollack CV Resume 2024.pdf";

export function NavBar() {

  function showHideTopArrow() {
    if (window.scrollY > 50) {
      document.querySelector(".nav-to-top-container").style.visibility = "visible";
      if (window.scrollY < 150) {
          document.querySelector(".nav-to-top-container").style.opacity = (window.scrollY-50)/100;
      } else {
          document.querySelector(".nav-to-top-container").style.opacity = 1;
      }
    } else {
      document.querySelector(".nav-to-top-container").style.visibility = "hidden";
    }
  }
  
  function underlineHeadings() { 
    if (window.scrollY >= document.querySelector("#contact").offsetTop) {
      document.querySelector(".nav-underline.contact").classList.add("scroll-in-section");
      document.querySelector(".nav-underline.about-me").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.projects").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.skills").classList.remove("scroll-in-section");
    } else if (window.scrollY >= document.querySelector("#projects").offsetTop) {
      document.querySelector(".nav-underline.projects").classList.add("scroll-in-section");
      document.querySelector(".nav-underline.about-me").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.skills").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.contact").classList.remove("scroll-in-section");
    } else if (window.scrollY >= document.querySelector("#skills").offsetTop) {
      document.querySelector(".nav-underline.skills").classList.add("scroll-in-section");
      document.querySelector(".nav-underline.about-me").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.projects").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.contact").classList.remove("scroll-in-section");
    } else if (window.scrollY >= document.querySelector("#about-me").offsetTop) {
      document.querySelector(".nav-underline.about-me").classList.add("scroll-in-section");
      document.querySelector(".nav-underline.skills").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.projects").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.contact").classList.remove("scroll-in-section");
    } else {
      document.querySelector(".nav-underline.about-me").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.skills").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.projects").classList.remove("scroll-in-section");
      document.querySelector(".nav-underline.contact").classList.remove("scroll-in-section");
    }
  }

  function scrollToTop() {
    window.scroll(0,0);
  }


  function scrollToSection(e) {
    const section = "#" + e.target.nextSibling.classList[1];
    const height = document.querySelector(section).offsetTop;
    window.scroll(0,height);
  }

  useEffect(() => {
    window.addEventListener("scroll",showHideTopArrow);
    window.addEventListener("scroll",underlineHeadings);
    return (() => {
      window.removeEventListener("scroll",showHideTopArrow);
      window.removeEventListener("scroll",underlineHeadings);
    })
  },[])

  return (
    <nav className="nav-bar">
      <ul>
        <li>
          {/* <a href="#about-me">About Me</a> */}
          <h4 onClick={scrollToSection}>About Me</h4>
          <div className="nav-underline about-me"></div>
        </li>
        <li>
          <h4 onClick={scrollToSection}>Skills</h4>
          <div className="nav-underline skills"></div>
        </li>
        <li>
          <h4 onClick={scrollToSection}>Projects</h4>
          <div className="nav-underline projects"></div>
        </li>
        <li>
          <h4 onClick={scrollToSection}>Contact</h4>
          <div className="nav-underline contact"></div>
        </li>
        <li>
          <h4 onClick={() => {window.location.href = guyCv}}>CV</h4>
          <div className="nav-underline cv"></div>
        </li>
        <li className="nav-to-top-container">
          <h4 onClick={scrollToTop}>
            <div className="arrow-up"></div>
          </h4>
        </li>
      </ul>
    </nav>
  )
}