import { Project } from "../Project/Project"
import { projects } from "../../data/ProjectsData"
import { useState, useEffect } from "react"

export function ProjectsContainer() {

  const [mode, setMode] = useState("");

  function changeMode() {
    if (window.innerWidth <= 480) {
      setMode("gif");
    } else {
      setMode("video");
    }
  }

  useEffect(() => {
    changeMode();
  },[])

  useEffect(() => {
    window.addEventListener("resize",changeMode);
    return () => {
      window.removeEventListener("resize",changeMode);
    }
  })

  return (
    <div className="projects-container">
      {Object.keys(projects).map((key,index) => <Project number={index+1} title={key} thumbnail={projects[key].thumbnail} gif={projects[key].gif} mode={mode} key={`project-${index+1}`}/>)}
    </div>
  )
}