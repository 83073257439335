// import pic from "../resources/javascript_logo.png";

export function SkillContainer({skill, url}) {
  // console.log("../resources/" + name.toLowerCase() + "_logo.png");
  return (
    <div className="skill-container">
      <img src={url} />
      <h3>{skill}</h3>
    </div>
  )
}