import { useState, useEffect } from "react";
import githubWhiteLogo from "../../resources/logos/github_white_logo.png";

function handleClick(e) {
  if (
    e.target.className.includes("project-modal") &&
    e.target.className !== "project-modal-contents"
  ) {
    let number;
    const className = e.target.classList[0];
    const elements = document.querySelectorAll("." + className);
    let numberOfElements = elements.length;
    for (let i = 0; i < numberOfElements; i++) {
      if (elements[i] === e.target) {
        number = i;
      }
    }
    document
      .querySelectorAll(".project-modal")
      [number].classList.remove("visible");
    // console.log(document.querySelectorAll(".project-modal-video-container")[number].children[0]);
    if (
      document.querySelectorAll(".project-modal-video-container")[number]
        .children[0].tagName === "VIDEO"
    ) {
      document
        .querySelectorAll(".project-modal-video-container")
        [number].children[0].pause();
    }
    document.querySelectorAll(".project-modal-video-container")[
      number
    ].children[0].currentTime = 0;
    document.querySelector("body").classList.remove("modal-open");
  }
}

export function ProjectModal({
  title,
  thumbnail,
  gif,
  url,
  gitHubUrl,
  element,
}) {
  const [mode, setMode] = useState("");

  function changeModalMode() {
    if (window.innerWidth <= 480) {
      setMode("gif");
    } else {
      setMode("video");
    }
  }

  useEffect(() => {
    changeModalMode();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", changeModalMode);
    return () => {
      window.removeEventListener("resize", changeModalMode);
    };
  });

  return (
    <div className="project-modal" onClick={handleClick}>
      <div className="project-modal-contents">
        <h2>{title}</h2>
        <h2 className="project-modal-close-button">+</h2>
        <>{element}</>
        <div className="project-modal-video-container">
          {mode === "gif" || !thumbnail ? (
            <img src={gif} />
          ) : mode === "video" ? (
            <video autoPlay muted loop webkit-playsinline="true" playsInline>
              <source src={thumbnail} type="video/mp4" />
            </video>
          ) : (
            <></>
          )}
        </div>
        <a href={url} target="_blank">
          Visit Site
        </a>
        {gitHubUrl && (
          <a href={gitHubUrl} target="_blank">
            <img src={githubWhiteLogo} alt="View repo on GitHub" />
          </a>
        )}
        {/* {url && <a href={gitHubUrl} target="_blank">See Project on GitHub</a>} */}
      </div>
    </div>
  );
}
