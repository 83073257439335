import { useEffect, useState } from "react";
import { findCountry } from "../../util/location/location";
import { NavBar } from "../../components/NavBar/NavBar";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { LandingPicture } from "../../components/LandingPicture/LandingPicture";
import { SkillsContainer } from "../../components/SkillsContainer/SkillsContainer";
import { ProjectsContainer } from "../../components/ProjectsContainer/ProjectsContainer";
import { ProjectModalsContainer } from "../../components/ProjectModalsContainer/ProjectModalsContainer";
import { ContactIcon } from "../../components/ContactIcon/ContactIcon";
import guyCv from "../../resources/pdfs/Guy Pollack CV Resume 2024.pdf";
// import './App.css';
import "./HomePage.css";

export function HomePage() {
  const [country, setCountry] = useState("");
  const [isLandingPictureLoaded, setIsLandingPictureLoaded] = useState(false);

  useEffect(() => {
    if (country === "") {
      findCountry().then((result) => {
        setCountry(result);
      });
    }
  });

  if (country === "" || country === "GB") return;

  return (
    <div className={isLandingPictureLoaded ? "home-page" : "home-page hidden"}>
      <NavBar />
      <div className="flex-container">
        <div className="landing">
          <PageHeader />
          <LandingPicture
            setIsLandingPictureLoaded={setIsLandingPictureLoaded}
          />
        </div>
        <section id="about-me">
          <h2>About Me</h2>
          <p>
            Hello, I'm Guy, a 30-year-old Brit who recently moved to Tel Aviv. I
            live with my partner, Rubik's cube, and coffee grinder, and I'm
            passionate about designing and building things for the web.
          </p>
          <p>
            I have a first class Astrophysics degree, which is where my interest
            in coding began. Ever since a PhD student at my university taught me
            how to use Python for data analysis, I have been captivated with
            solving problems creatively using software.
          </p>
          <p>
            I took this interest to an education technology start-up, teaching
            myself Visual Basic and SQL to deliver projects faster and remove
            manual work.
          </p>
          <p>
            I then worked for the UK Government for four years, investigating
            high-level financial crime. Using my programming skills, I created
            software solutions for my team and organisation, significantly
            improving the efficiency and quality of our work handling large and
            complex data sets. During my time I received rewards for outstanding
            performance.
          </p>
          <p>
            While these tasks were interesting, I missed the fun and
            interactivity of the gaming and social websites I have always loved.
            So recently, I completed Codecademy's Front-End Developer online
            bootcamp. Through 500+ hours of lessons, exercises and projects
            (plus additional back-end courses), I discovered my passion for the
            world of web design and development, and learnt the latest
            technologies to build beautiful and useful websites, like the ones
            you will find in this portfolio! My Pokemon project (see below) won
            1st place in{" "}
            <a
              href="https://www.codecademy.com/pages/project-showcase"
              target="_blank"
            >
              Codecademy's Learner Project Showcase
            </a>
            .
          </p>
          <p>
            Since May 2023 I have worked as a full-stack developer at{" "}
            <a href="https://www.heystack.com" target="_blank">
              Heystack
            </a>
            , an AI-powered talent discovery platform start-up in Tel Aviv,
            where I have put my learning into action, working on both the front
            and back-end using the MERN stack with TypeScript, and building
            projects with the design and product teams.
          </p>
          <p>
            I'm now looking for my next challenge as a full-stack developer,
            hoping to bring my skills and passion to an amazing company. Could
            it be yours? 🙏
          </p>
        </section>
        <section id="skills">
          <h2>Skills</h2>
          <div className="skills-container">
            <SkillsContainer />
          </div>
        </section>
        <section id="projects">
          <h2>Projects</h2>
          <ProjectsContainer />
        </section>
        <section id="contact">
          <h2>Contact</h2>
          <div className="contact-icons-container">
            <ContactIcon
              icon="fa-solid fa-envelope"
              text="info@guypollack.com"
              url="mailto:info@guypollack.com"
            />
            <ContactIcon
              icon="fa-brands fa-linkedin-in"
              url="https://www.linkedin.com/in/guy-pollack-0b2890174/"
            />
            <div className="contact-icon">
              <a href={guyCv}>
                <div className="contact-icon-box">
                  <p id="cv-box-text">CV</p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
      <ProjectModalsContainer />
    </div>
  );
}
